import React from "react"

import calcStyles from '../hold_calculator.module.scss'
import CoverChart from "./cover_chart"
import holdStyles from './display_hold.module.scss'

const DisplayCover = ({ coverPct, pushPct, lossPct, ev, lineOne, lineTwo, coverVisible }) => {
    
    // interpret coverpct //
    var coverAssesmentType = ''
    var coverAssesmentCovering = ''
    var coverAssesment = ''

    if (coverVisible) {
        // what kind of team //
        if (lineOne < .5 && lineOne > -.5) {
            coverAssesmentType = `A true pickem`
        } else if (lineOne < 0) {
            coverAssesmentType = `A true ${Math.abs(lineOne)} point favorite`
        } else {
            coverAssesmentType = `A true ${Math.abs(lineOne)} point dog`
        }
        // covering what type of line // 
        if (lineTwo < .5 && lineTwo > -.5) {
            coverAssesmentCovering = `win and cover a pickem`
        } else if (lineTwo < 0) {
            coverAssesmentCovering = `win by more than ${Math.abs(lineTwo)}`
        } else {
            coverAssesmentCovering = `lose by less than ${Math.abs(lineTwo)} or win`
        }
        // put together //
        coverAssesment = `${coverAssesmentType} has a ${Math.round(100*coverPct,2)}% chance to ${coverAssesmentCovering}`
    }

    return (

        <div>
            <h2 className={calcStyles.title}>
                Cover Probability and EV
            </h2>
            <h3 className={calcStyles.subtitle}>
                Probability the team covers the spread
            </h3>
            <CoverChart
                coverPct={coverPct}
                pushPct={pushPct}
                lossPct={lossPct}
                ev={ev}
                coverVisible={coverVisible}
            />
            <div className={holdStyles.hold_assessment_container}>
                {coverVisible ? coverAssesment : ''}
            </div>
        </div>
    
    )

}

export default DisplayCover