import React from "react"

import Layout from '../../components/layout'
import Head from '../../components/head'

import { Link } from "gatsby"
import CalculatorDrop from "../../components/Title Drop/calculator_drop"
import CoverCalc from "../../components/Calculators/cover_probability"

import calcStyles from './calculators.module.scss'

const CoverProbabilityCalc = () => {
    
    return (
        <Layout>
            <Head
                title="NFL Cover Probabllty Calculator"
                pageDescription="A calcuator that uses a generalized cover probability model to calculate EV for a given bet"
            />
                <CalculatorDrop
                    startingItem={'NFL Cover Probability'}
                />
                <p className={calcStyles.calculator_subtitle}>
                    A free tool for determining the cover probability and expected value of a bet based on a projected line and market line
                </p>
                <CoverCalc/>
                <div className={calcStyles.content_container}>
                    <h3 className={calcStyles.content_h3}>
                        What is cover probability in the NFL?
                    </h3>
                    <p className={calcStyles.content_p}>
                        Put simply, cover probability is the probability that a particular team will cover the market spread available at sportsbooks. Since bettors are always on the hunt for opportunities to make money, any spreads that have better than 50/50 odds tend to be bet down until sportsbooks adjust the line.<br className={calcStyles.content_break}/>
                        As a result, the cover probability for most spreads is expected to be 50%. But what if you have a point of view that differs from the market and you want to know the odds that the team will cover? This is where our model comes into play.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        Cover probability calculator
                    </h3>
                    <p className={calcStyles.content_p}>
                        The nfelo cover probability looks at a historic distribution of NFL spreads and game outcomes to determine how likely it is that a team favored by X will win by Y, or conversely, how likely a team getting X points will cover Y points. The model is fairly complex, but you can read more about how it was constructed here:<br className={calcStyles.content_break}/>
                    </p>
                    <div className={calcStyles.articleLinkContainer}>
                        <Link
                            className={calcStyles.articleLink}
                            to="/analysis/margin-probabilities-from-nfl-spreads"
                        >
                            How does it work?
                        </Link>
                    </div>
                    <p className={calcStyles.content_p}>
                        To make it easy to leverage this model, we’ve created a calculator that tells you the cover probability and expected value of a bet given a team’s “true” value and the current market spread.<br className={calcStyles.content_break}/>
                        The “true” value of a team is how many points better or worse they actually are, while the “market” value of a team is how many points better or worse they are according to a consensus spread from sportsbooks.<br className={calcStyles.content_break}/>
                        When a team’s true value is higher than their market value, their chances to cover the market spread rises above 50% and there’s the potential for a bet with positive expected value.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        When to use the NFL cover probability calculator                    </h3>
                    <p className={calcStyles.content_p}>
                        You might think that any team with a greater than 50% chance of covering makes for a positive expected value bet, but this isn’t always the case. For a bet to be positive EV, the team’s cover probability has to be high enough to compensate for the additional vig the sportsbook charges. This is where a cover probability calculator comes into play..<br className={calcStyles.content_break}/>
                        By using a cover probability calculator, you can see whether or not your line on a team is different enough from the sportsbook line to create a positive expected value bet--assuming, of course, that your line is right!
                    </p>
                </div>
        </Layout>
    )

}

export default CoverProbabilityCalc