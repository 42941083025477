
import React from "react"
import {VictoryPie, VictoryLabel } from 'victory'


const CoverChart = ({coverPct, pushPct, lossPct, ev, coverVisible}) => {

    var labelStyle = {
        labels: {
            fontSize: 20,
            fill: '#8c8c8c'
        }
    }

    var chartColors = [
        '#6effd8',
        '#a3a3a3',
        '#fc6d9a'
    ]

    var pieData = [
        { x: "Cover", y: parseFloat(coverPct) },
        { x: "Push", y: parseFloat(pushPct) },
        { x: "Loss", y: parseFloat(lossPct) }
    ]

    // construct data conditionally based on if push exists //
    if (parseFloat(pushPct) === 0) {
        chartColors = [
            '#6effd8',
            '#fc6d9a'
        ]
    
        pieData = [
            { x: "Cover", y: parseFloat(coverPct) },
            { x: "Loss", y: parseFloat(lossPct) }
        ]
    }

    // overide if data not supposed be visible //
    if (!coverVisible) {
        chartColors = [
            '#e8e8e8'
        ]
    
        pieData = [
            { x: "", y: 1 }
        ]
    }

    return (

        <svg viewBox="0 0 410 240" width="100%" height="100%">
            <VictoryPie
                standalone={false}
                data={pieData}
                colorScale={chartColors}
                labels={({ datum }) => coverVisible ? `${Math.round(100*datum.y)}%` : ''}
                style={labelStyle}
                height={400}
                width={400}
                cornerRadius={20}
                startAngle={-110}
                endAngle={110}
                innerRadius={120}
                padAngle={1}
            />
            <VictoryLabel
                  textAnchor="middle" verticalAnchor="middle"
                  x={200} y={180}
                  text={coverVisible ? `${Math.round(100*ev,2)}%` : '--'}
                  style={{ fontSize: 40 }}
            />
            <VictoryLabel
                  textAnchor="middle" verticalAnchor="middle"
                  x={200} y={220}
                  text={'Expected Value'}
                  style={{ fontSize: 20 }}
            />
        </svg>

    )
}

export default CoverChart



