import React, { useState } from "react"
import { useStaticQuery } from "gatsby"

import calcStyles from './cover_calculator.module.scss'
import DisplayCover from "./helpers/display_cover"

const CoverCalc = () => {
   
    // pull lines and outputs //
    const data = useStaticQuery(graphql`
        query {
            allCoverProbabilitiesCsv {
                nodes {
                    market_line
                    true_line
                    push_probability
                    cover_probability
                    loss_probability
                }
            }
            marketLines: allCoverProbabilitiesCsv {
                distinct (
                    field: market_line
                )
            }
            trueLines: allCoverProbabilitiesCsv {
                distinct (
                    field: true_line
                )
            }
        }
    `)

    // set initial states //
    const [ev, setEv] = useState(0.05)
    const [coverPct, setCoverPct] = useState(0.50)
    const [pushPct, setPushPct] = useState(0.09)
    const [lossPct, setLossPct] = useState(0.41)
    const [calcVisible, setCalcVisible] = useState(true)
    const [lineOne, setLineOne] = useState(-3.5)
    const [lineTwo, setLineTwo] = useState(-3.0)
    const [alertMsgOne, setAlertMessageOne] = useState("")
    const [alertMsgTwo, setAlertMessageTwo] = useState("")
    const [alertVisibleOne, setAlertVisibleOne] = useState(false)
    const [alertVisibleTwo, setAlertVisibleTwo] = useState(false)

    // func for imperceisely handling float-point rounding hell //
    function truncate (num, places) {
        return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
    }

    // func for rounding consistently //
    function roundHalf(num){
        return truncate(Math.round(2 * parseFloat(num))/2 +.01,1)
    }

    // update text entries when value is wrong //
    // active field gets raw entry text, all others are wiped //
    const updateWithError = (activeEntry, rawEntry) => {
        
        // set lines //
        // line 1//
        if (activeEntry === 'lineOne') {
            // toggle alerts //
            setAlertVisibleOne(true)
            setAlertVisibleTwo(false)
            // update //
            setLineOne(
                rawEntry
            )
        }

        
        // line 2//
        if (activeEntry === 'lineTwo') {
            // control viz //
            setAlertVisibleOne(false)
            setAlertVisibleTwo(true)
            // update // 
            setLineTwo(
                rawEntry
            )
        }
        
        // set hold //
        setCoverPct('--')
        setPushPct('--')
        setLossPct('--')
        setCalcVisible(false)

    }

    // update logic if correctly formatted item was provided //
    // visibility and calcs controlls are contained to these update funcs //
    const updateWithValue = (activeEntry, rawEntry) => {
        
        if (activeEntry === 'lineOne') {
            // always set entry when dealing with controlled state //
            setLineOne(rawEntry)

            // since update is valid, remove error //
            setAlertMessageOne('')

            // if americanTwo still has error we can't update //
            if (lineTwo === '' || alertMsgTwo !== '') {
                setAlertVisibleOne(false)
                setAlertVisibleTwo(true)
            } else {
                
                // clear warnings //
                setAlertVisibleOne(false)
                setAlertVisibleTwo(false)

                // parse entries to nearest .5 //
                const parsedOne = roundHalf(rawEntry)
                const parsedTwo = roundHalf(lineTwo)

                // loop through cover prob DB and set values //
                data.allCoverProbabilitiesCsv.nodes.forEach((node) => {
                    if (
                        roundHalf(node['true_line']) === parsedOne &&
                        roundHalf(node['market_line']) === parsedTwo
                    ) {
                        // set probs //
                        setCoverPct(node['cover_probability'])
                        setPushPct(node['push_probability'])
                        setLossPct(node['loss_probability'])
                        setEv(
                            node['cover_probability'] - 1.1 * node['loss_probability']
                        )
                        setCalcVisible(true)
                    }
                })
            }
        }

        if (activeEntry === 'lineTwo') {
            // always set entry when dealing with controlled state //
            setLineTwo(rawEntry)

            // since update is valid, remove error //
            setAlertMessageTwo('')

            // if lineOne still has error we can't update //
            if (lineOne === '' || alertMsgOne !== '') {
                setAlertVisibleOne(true)
                setAlertVisibleTwo(false)
            } else {
                
                // clear warnings //
                setAlertVisibleOne(false)
                setAlertVisibleTwo(false)

                // parse entries to nearest .5 //
                const parsedOne = roundHalf(lineOne)
                const parsedTwo = roundHalf(rawEntry)

                // loop through cover prob DB and set values //
                data.allCoverProbabilitiesCsv.nodes.forEach((node) => {
                    if (
                        roundHalf(node['true_line']) === parsedOne &&
                        roundHalf(node['market_line']) === parsedTwo
                    ) {
                        // set probs //
                        setCoverPct(node['cover_probability'])
                        setPushPct(node['push_probability'])
                        setLossPct(node['loss_probability'])
                        setEv(
                            node['cover_probability'] - 1.1 * node['loss_probability']
                        )
                        setCalcVisible(true)
                    }
                })
            }
        }

    }

    // handle the actual field update //

    // one //
    const updateLineOne = event => {

        const line = event.target.value
        const lineFormatted = Math.abs(roundHalf(line))
        
        if (isNaN(lineFormatted) || lineFormatted > 30) {
            // alert msg if odds not valid //
            setAlertMessageOne('Your line must be between -30 and 30')
            updateWithError('lineOne', line)
        } else {
            // update 
            updateWithValue('lineOne', line)
        }

    }

    // two //
    const updateLineTwo = event => {

        const line = event.target.value
        const lineFormatted = Math.abs(roundHalf(line))
        
        if (isNaN(lineFormatted) || lineFormatted > 24) {
            // alert msg if odds not valid //
            setAlertMessageTwo('Market line must be between -24 and 24')
            updateWithError('lineTwo', line)
        } else {
            // update 
            updateWithValue('lineTwo', line)
        }

    }

    
    return (

        <div className={calcStyles.calc_wrapper}>
            <div className={calcStyles.cover_container}>
                <div className={calcStyles.hold_panel}>
                    <div className={calcStyles.check_container}>
                        <h2 className={calcStyles.title}>
                            Your Line
                        </h2>
                        <h3 className={calcStyles.subtitle}>
                            Enter your projected line 
                        </h3>
                        <div className={calcStyles.check_input_container}>
                            <div className={calcStyles.check_div} data-active={alertVisibleOne}>
                                {(alertVisibleOne===false) ? '\u2713' : '\u2297' }
                            </div>
                            <input className={calcStyles.input_value}
                                id={'lineOne'}
                                name={'lineOne'}
                                type="text"
                                value={lineOne}
                                onChange={updateLineOne}
                            />
                        </div>
                    </div>
                    <div className={calcStyles.check_container}>
                        <h2 className={calcStyles.title}>
                            Market Line
                        </h2>
                        <h3 className={calcStyles.subtitle}>
                            Enter the current market line
                        </h3>
                        <div className={calcStyles.check_input_container}>
                            <div className={calcStyles.check_div} data-active={alertVisibleTwo}>
                                {(alertVisibleTwo===false) ? '\u2713' : '\u2297' }
                            </div>
                            <input className={calcStyles.input_value}
                                id={'lineTwo'}
                                name={'lineTwo'}
                                type="text"
                                value={lineTwo}
                                onChange={updateLineTwo}
                            />
                        </div>
                    </div>
                </div>
                <div className={calcStyles.hold_panel}>
                    <div className={calcStyles.hold_right_panel_wrapper}>
                        <div className={calcStyles.hold_right_panel}>
                            <DisplayCover
                                coverPct={coverPct}
                                pushPct={pushPct}
                                lossPct={lossPct}
                                ev={ev}
                                lineOne={lineOne}
                                lineTwo={lineTwo}
                                coverVisible={calcVisible}
                            />
                        </div>
                    </div>
                </div>
                <div className={calcStyles.alert_container} data-active={alertVisibleOne}>
                        <p className={calcStyles.alert} data-active={alertVisibleOne}>
                            {alertMsgOne}
                        </p>
                    </div>
                    <div className={calcStyles.alert_container} data-active={alertVisibleTwo}>
                        <p className={calcStyles.alert} data-active={alertVisibleTwo}>
                            {alertMsgTwo}
                        </p>
                    </div>
            </div>
        </div>

    )
}

export default CoverCalc